import axios from "axios";

import { baseUrl } from "../constant/url";
import { getTokenCookie } from "../utils/cookies";

export async function getFinancialReport(startDate, endDate, idho, idhub) {
  const { data: resp } = await axios({
    method: "get",
    url: `${baseUrl}/financial/hq/report?startDate=${startDate}&endDate=${endDate}`,
    headers: {
      Authorization: `Bearer ${getTokenCookie()}`,
      idho: idho,
      idhub: idhub,
    },
  });
  return resp.data;
}

export async function getFinancialReportDetail(date) {
  const { data: resp } = await axios({
    method: "get",
    url: `${baseUrl}/financial/report/${date}`,
    headers: {
      Authorization: `Bearer ${getTokenCookie()}`,
    },
  });
  return resp.data;
}
