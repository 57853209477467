import { render, staticRenderFns } from "./DialogDetailHubComponent.vue?vue&type=template&id=9781db84&scoped=true"
import script from "./DialogDetailHubComponent.vue?vue&type=script&lang=js"
export * from "./DialogDetailHubComponent.vue?vue&type=script&lang=js"
import style0 from "./DialogDetailHubComponent.vue?vue&type=style&index=0&id=9781db84&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9781db84",
  null
  
)

export default component.exports