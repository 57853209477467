<template>
  <div class="InvoiceManagement">
    <v-container class="ma-0">
      <v-row>
        <v-col sm="4" class="pa-0">
          <v-text-field
            v-model="date"
            type="date"
            outlined
            label="Date"
            dense
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col sm="2" class="pa-0">
          <v-select
            class="mr-2"
            outlined
            dense
            label="Origin"
            item-text="text"
            item-value="value"
            :items="originList"
            v-model="origin"
          ></v-select>
        </v-col>

        <v-col sm="2" class="pa-0">
          <v-select
            outlined
            dense
            label="Destination"
            item-text="text"
            item-value="value"
            :items="destinationList"
            v-model="destination"
          ></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col sm="4" class="pa-0">
          <v-select
            outlined
            dense
            label="Status"
            item-text="text"
            item-value="value"
            :items="statusList"
            v-model="status"
          ></v-select>
        </v-col>
      </v-row>

      <v-row>
        <v-col sm="4" class="pa-0 mr-2">
          <v-btn depressed color="warning" class="mr-2" @click="search"
            >Search</v-btn
          >

          <v-btn depressed color="error" @click="refresh">refresh</v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-data-table
      :headers="headers"
      :items="filteredItems"
      :sort-by="sortBy"
      :loading="isLoading"
      :sort-desc="[true]"
      item-key="manifestId"
      :expanded.sync="expanded"
      :single-expand="true"
      show-expand
    >
      <template v-slot:top>
        <dialog-create-invoice-component
          @refresh="fetchManifests"
          :manifest="itemDetail"
          ref="createInvoicetDialog"
        />

        <dialog-send-to-jne-component
          ref="reqJne"
          :detailItem="payloadJne"
          @refresh="fetchManifests"
          :fetchManifests="fetchManifests"
        />
        <dialog-send-to-sentral-component
          ref="reqSentral"
          :detailItem="payloadJne"
          @refresh="fetchManifests"
          :fetchManifests="fetchManifests"
        />
      </template>
      <template v-slot:[`item.tgl`]="{ item }">
        {{ formatTgl(item.description) }}
      </template>

      <!-- TODO: Hide this button for now -->
      <template v-slot:[`item.action`]="{ item }">
        <!-- <v-btn
          depressed
          class="mr-2"
          small
          color="success"
          @click="createInvoice(item)"
          :disabled="item.status != 'MANIFEST_CREATED'"
        >
          create invoice
        </v-btn> -->
        <v-btn
          v-if="item.orderDetail[0]?.Order?.OrderType == 'domestic'"
          depressed
          small
          color="primary"
          @click="sendJne(item)"
          :disabled="item.status != 'MANIFEST_CREATED'"
        >
          Carrier 1
        </v-btn>
        <v-btn
          v-else
          depressed
          small
          color="primary"
          @click="openDialog(item)"
          :disabled="item.status != 'MANIFEST_CREATED'"
        >
          Carrier 1
        </v-btn>
      </template>

      <template v-slot:expanded-item="{ item, headers }">
        <td :colspan="headers.length" class="pa-0">
          <drop-down-manifest-component
            :order="item.order"
            :items="item"
            :fetchManifests="fetchManifests"
          />
        </td>
      </template>
    </v-data-table>

    <v-snackbar timeout="2000" v-model="snackbar" top color="orange">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-h6 grey lighten-2">
          Pick Up Option
        </v-card-title>

        <v-container fluid>
          <v-row align="center">
            <v-col cols="6">
              <v-subheader> Select Type Vechicle </v-subheader>
            </v-col>

            <v-col cols="6">
              <v-select
                v-model="vehicle"
                :items="['MOTOR', 'MOBIL']"
                label="Select"
                persistent-hint
                return-object
                single-line
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-menu
                ref="menu"
                v-model="menuDate"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="datePickup"
                    label="Date Pickup"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="datePickup"
                  no-title
                  scrollable
                  @input="menuDate = false"
                >
                </v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="timePickup"
                label="Time Pickup"
                prepend-icon="mdi-clock-time-four-outline"
              ></v-text-field>
            </v-col>
            <v-col cols="12" v-if="showAddress">
              <v-select
                v-model="alamatPickup"
                :items="listCity"
                label="Alamat Pick Up"
                persistent-hint
                return-object
                single-line
                @input="selectAddress"
              ></v-select>
            </v-col>
          </v-row>
        </v-container>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="btn"
            variant="primary"
            color="primary"
            elevation="2"
            @click="assignBest"
          >
            <v-progress-circular
              v-if="isLoading"
              indeterminate
              color="white"
            ></v-progress-circular>
            <span v-else>Request</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import DialogCreateInvoiceComponent from "../../../components/dashboard/inbound/DialogCreateInvoiceComponent.vue";
import { formatToRupiah } from "../../../utils/formatter";
import { baseUrl } from "../../../constant/url";
import { getTokenCookie, getProfileCookie } from "../../../utils/cookies";
import moment from "moment";
import DropDownManifestComponent from "../../../components/dashboard/inbound/DropDownManifestComponent.vue";
import DialogSendToJneComponent from "../../../components/dashboard/inbound/DialogSendToJneComponent.vue";
import DialogSendToSentralComponent from "../../../components/dashboard/inbound/DialogSendToSentralComponent.vue";

export default {
  components: {
    DialogCreateInvoiceComponent,
    DropDownManifestComponent,
    DialogSendToJneComponent,
    DialogSendToSentralComponent,
  },
  name: "ManageOutbound",
  data() {
    return {
      dialog: false,
      date: "",
      datePickup: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      timePickup: null,
      menuDate: false,
      timeDate: false,
      origin: "",
      expanded: [],
      destination: "",
      snackbar: false,
      snackbarText: "",
      originList: [],
      destinationList: [],
      status: "",
      statusList: [],
      isLoading: false,
      itemDetail: {
        manifestId: "",
        date: "",
        moda: "",
        origin: "",
        destination: "",
        totalColly: "",
        totalWeight: "",
      },
      sortBy: [
        "date",
        "origin",
        "destination",
        "totalColly",
        "totalWeight",
        "totalPrice",
        "status",
      ],
      headers: [
        { text: "No Manifest", value: "manifestId" },
        { text: "Date", value: "tgl" },
        { text: "Hub Origin", value: "origin" },
        { text: "Tujuan", value: "destination" },
        { text: "Total Koli", value: "totalColly" },
        { text: "Total Berat", value: "totalWeight" },
        { text: "Total Harga", value: "totalPrice" },
        { text: "Keterangan", value: "description" },
        { text: "Status", value: "status" },
        { text: "Action", value: "action", sortable: false },
        { text: "Info", value: "data-table-expand", filterable: false },
      ],
      items: [],
      filteredItems: [],
      payloadJne: "",
      vehicle: "MOTOR",
      dataStore: [],
      listCity: [],
      cityPickup: "",
      alamatPickup: "",
      showAddress: false,
    };
  },
  methods: {
    search() {
      let data = this.items;
      if (this.date) {
        data = this.filterDate(data);
      }

      if (this.origin) {
        data = this.filterOrigin(data);
      }

      if (this.destination) {
        data = this.filterDestination(data);
      }

      if (this.status) {
        data = this.filterStatus(data);
      }

      this.filteredItems = data;
    },
    refresh() {
      this.filteredItems = this.items;
      this.date = "";
      this.destination = "";
      this.origin = "";
      this.status = "";
    },
    generateList(data) {
      this.statusList = [
        "ALL_STATUS",
        ...new Set(data.map((item) => item.status)),
      ];
      this.originList = [
        "All Hub",
        ...new Set(data.map((item) => item.origin)),
      ];
      this.destinationList = [
        "Semua Kota",
        ...new Set(data.map((item) => item.destination)),
      ];
    },
    filterOrigin(data) {
      return data.filter((el) => {
        if (this.origin === "All Hub") return true;
        return el.origin == this.origin;
      });
    },
    filterDestination(data) {
      return data.filter((el) => {
        if (this.destination === "Semua Kota") return true;
        return el.destination == this.destination;
      });
    },
    filterStatus(data) {
      return data.filter((el) => {
        if (this.status === "ALL_STATUS") return true;
        return el.status == this.status;
      });
    },
    filterDate(data) {
      return data.filter((el) => {
        const d = el.date.split(",");
        return d[0] === this.date;
      });
    },
    createInvoice(item) {
      this.itemDetail = item;
      this.$refs.createInvoicetDialog.toggleDialog();
    },
    async fetchManifests() {
      this.isLoading = true;

      axios({
        method: "get",
        url: `${baseUrl}/Manifest`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
          status: "1,2,3,4,5,6,7",
          idho: await getProfileCookie().KCX_HOIDHO,
        },
      })
        .then((res) => {
          const filteredManifest = res.data.data.filter(
            (el) => el.IDManifest != 0
          );

          const items = filteredManifest.map((manifest) => {
            return {
              manifestId: manifest.IDManifest,
              date: moment(manifest.Date)
                // .add(7, "hours")
                .format("YYYY-MM-DD, HH:mm"),
              origin: manifest.HubDetail.NamaHub,
              destination: manifest.KotaTujuan,
              totalColly: manifest.TotalKoli,
              totalWeight: manifest.TotalBerat,
              status: manifest.Status,
              description: manifest.Keterangan,
              idStatus: manifest.IDStatus,
              orderDetail: manifest.Order,
              orderType: manifest.Order.OrderType,
              hubDetail: manifest.HubDetail,
              order: manifest.Order.map((el) => {
                return {
                  orderId: el.Order.IDOrder,
                  wbNumber: el.WBNumber.replace(/\s/g, ""),
                  colly: el.Order.TotalKoli,
                  weight: el.Order.TotalBerat,
                  transaction: formatToRupiah(el.Order.TotalBiaya),
                  idStatus: el.Order.PackageStatusIDPackageStatus,
                  detail: el.Order,
                  hub: manifest.HubDetail,
                  price3PL: el.Price3PL,
                  resi3PL: el.Resi3PL,
                  vendor: el.Order.Vendor,
                  noRef: el.Order.NoRef,
                  orderType: el.Order.OrderType,
                };
              }),
              totalPrice: formatToRupiah(
                manifest.Order.reduce((acc, obj) => {
                  return Number(acc) + Number(obj.Order.TotalBiaya);
                }, 0)
              ),
            };
          });

          this.items = items;
          this.filteredItems = items;
          this.generateList(items);
          this.isLoading = false;
        })
        .catch(() => {
          this.items = [];
          this.filteredItems = [];
          this.isLoading = false;
        });
    },
    async sendJne(item) {
      this.payloadJne = item;
      this.payloadJne["tPickup"] = await item.description.split(" ");
      if (item.order[0].vendor == "JNE") {
        this.$refs.reqJne.toggleDialog();
        this.$refs.reqJne.setTime();
        await this.$refs.reqJne.getKodepos(item.hubDetail.City);
      } else {
        this.$refs.reqSentral.toggleDialog();
        this.$refs.reqSentral.setTime();
      }
    },
    async openDialog(items) {
      this.listCity = [];
      this.cityPickup = "";
      this.alamatPickup = "";
      this.dialog = true;
      this.dataStore = items;
      let citySNI = this.dataStore.hubDetail.City.CitySNI;

      if (citySNI != "CGK") {
        this.showAddress = true;
        axios({
          method: "get",
          url: `${baseUrl}/Hub/Find/CGK`,
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
          },
        })
          .then((respose) => {
            respose.data.data.map((item) => {
              this.listCity.push(item.Alamat);
            });
          })
          .catch(() => {
            this.snackbar = true;
            this.dialog = false;
            this.isLoading = false;
            this.snackbarText = "error";
          });
      } else {
        this.showAddress = false;
        this.cityPickup = this.dataStore.hubDetail.Alamat;
      }
    },
    async assignBest() {
      this.isLoading = true;
      if (this.datePickup && this.timePickup) {
        this.dataStore["cityPickup"] = this.cityPickup;
        axios({
          method: "put",
          url: `${baseUrl}/AssignBest/${this.dataStore.manifestId}`,
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
            vehicle: this.vehicle,
            datepickup: moment(this.datePickup).format("DD-MM-YYYY"),
            timepickup: this.timePickup,
          },
          data: this.dataStore,
        })
          .then(() => {
            this.snackbar = true;
            this.dialog = false;
            this.isLoading = false;

            this.snackbarText = "success";
            this.fetchManifests();
          })
          .catch(() => {
            this.snackbar = true;
            this.dialog = false;
            this.isLoading = false;
            this.snackbarText = "error";
            this.fetchManifests();
          });
      } else {
        this.snackbar = true;
        this.isLoading = false;
        this.snackbarText = "input do not empty";
      }
    },
    selectAddress(item) {
      this.cityPickup = item;
    },
    formatTgl(date) {
      let fDate = moment(date).format("YYYY-MM-DD hh:mm");
      if (fDate != "Invalid date") {
        return moment(date).format("YYYY-MM-DD hh:mm");
      } else {
        return date;
      }
    },
  },
  created() {
    this.fetchManifests();
  },
};
</script>

<style lang="scss" scoped>
.InvoiceManagement {
  background-color: white;
  border-radius: 5px;
  padding: 16px;
}
</style>
