<template>
  <div class="DialogOrderTransactionComponent">
    <v-dialog
      v-model="detailOrderDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="toggleDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Detail Order</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items class="mt-5"> </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col class="mt-5">
              <div v-if="isLoading" class="text-center">
                <v-progress-circular
                  class="mt-5"
                  :size="70"
                  :width="7"
                  color="purple"
                  indeterminate
                ></v-progress-circular>
              </div>
              <div v-else>
                <v-expansion-panels inset focusable v-if="items.length > 0">
                  <v-expansion-panel v-for="(order, i) in items" :key="i">
                    <v-expansion-panel-header>{{
                      order.detail.WBNumber
                    }}</v-expansion-panel-header>
                    <v-expansion-panel-content
                      v-if="order.orderType == 'domestic'"
                    >
                      <div class="pa-5">
                        <v-row>
                          <v-col cols="6">
                            <h2>Shipper Information</h2>
                            <v-divider></v-divider>
                            <div class="pa-5">
                              <p>
                                <strong>Nama</strong> :
                                {{ order.detail.Order.ShipperLoc.Nama }}
                              </p>
                              <p>
                                <strong>Telp</strong>:
                                {{ order.detail.Order.ShipperLoc.Telp }}
                              </p>
                              <p>
                                <strong>Alamat</strong>:
                                {{ order.detail.Order.ShipperLoc.Alamat }}
                              </p>
                            </div>
                          </v-col>
                          <v-col cols="6">
                            <h2>Recipient divider</h2>
                            <v-divider></v-divider>

                            <div class="pa-5">
                              <p>
                                <strong>Nama </strong>:
                                {{ order.detail.Order.Recipient.Nama }}
                              </p>
                              <p>
                                <strong>Telp</strong> :{{
                                  order.detail.Order.Recipient.Telp
                                }}
                              </p>
                              <p>
                                <strong>Alamat</strong>:
                                {{ order.detail.Order.Recipient.Alamat }}
                              </p>
                              {{ order.detail.Order.Recipient.Kota }}
                              {{
                                order.detail.Order.Recipient.KelurahanKodePos
                              }}
                            </div>
                          </v-col>
                          <v-col cols="12">
                            <h3>Package Information</h3>
                            <v-divider></v-divider>
                            <v-row>
                              <v-col cols="6">
                                <div class="pa-5">
                                  <p>
                                    <strong>Moda</strong> :
                                    {{ order.detail.Order.Moda }}
                                  </p>
                                  <p>
                                    <strong>Tipe Paket</strong> :{{
                                      order.detail.Order.Kategory
                                    }}
                                  </p>
                                  <p>
                                    <strong>Total Koli</strong> :{{
                                      order.detail.TotalKoli
                                    }}
                                  </p>
                                  <p>
                                    <strong>Keterangan</strong> :{{
                                      order.detail.Keterangan
                                    }}
                                  </p>
                                </div>
                              </v-col>
                              <v-col cols="6">
                                <div class="pa-5">
                                  <p>
                                    <strong>Instruksi Khusus</strong> :{{
                                      order.detail.Instruction
                                    }}
                                  </p>
                                  <p>
                                    <strong>Diskon</strong> :{{
                                      order.detail.Diskon
                                    }}
                                  </p>
                                  <p>
                                    <strong>Nilai Barang</strong> :{{
                                      order.detail.EstimasiNilai
                                    }}
                                  </p>
                                  <p>
                                    <strong>Asuransi</strong> :{{
                                      order.detail.Order.IsInsurance
                                        ? "Ya"
                                        : "Tidak"
                                    }}
                                  </p>
                                </div>
                              </v-col>
                              <v-col cols="12">
                                <h2>Detail Koli</h2>
                                <v-divider></v-divider>
                                <div
                                  class="ItemFormComponent-Result"
                                  v-if="order.detail.Order.Collies.length > 0"
                                >
                                  <v-data-table
                                    :headers="headers"
                                    :items="order.detail.Order.Collies"
                                  >
                                    <template v-slot:[`item.total`]="{ item }">
                                      {{ item.Terberat * item.JumlahKoli }}
                                    </template>
                                    <template
                                      v-slot:[`item.IsPacking`]="{ item }"
                                    >
                                      {{ item.IsPacking ? "Ya" : "Tidak" }}
                                    </template>
                                  </v-data-table>
                                </div>
                              </v-col>
                              <v-col cols="12">
                                <h2>Detail Biaya</h2>
                                <v-divider></v-divider>
                                <v-simple-table>
                                  <thead>
                                    <tr>
                                      <th>Koli</th>
                                      <th>Total Berat</th>
                                      <th>Total Biaya Packing</th>
                                      <th>Biaya Kirim</th>
                                      <th>Diskon</th>
                                      <th>PPN</th>
                                      <th>Asuransi</th>
                                      <th>Total Biaya</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>{{ order.detail.TotalKoli }}</td>
                                      <td>{{ order.detail.TotalBerat }} Kg</td>
                                      <td>
                                        {{
                                          toRupiah(
                                            order.detail.Order.TotalBiayaPacking
                                          )
                                        }}
                                      </td>
                                      <td>
                                        {{
                                          toRupiah(
                                            order.detail.TotalBiaya -
                                              (order.detail.Diskon +
                                                order.detail.PPN +
                                                order.detail.Order
                                                  .TotalBiayaPacking +
                                                order.detail.Order.Insurance)
                                          )
                                        }}
                                      </td>
                                      <td>
                                        {{ toRupiah(order.detail.Diskon) }}
                                      </td>
                                      <td>{{ toRupiah(order.detail.PPN) }}</td>
                                      <td>
                                        {{
                                          toRupiah(order.detail.Order.Insurance)
                                        }}
                                      </td>
                                      <td>
                                        {{ toRupiah(order.detail.TotalBiaya) }}
                                      </td>
                                    </tr>
                                  </tbody>
                                </v-simple-table>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </div>
                    </v-expansion-panel-content>
                    <v-expansion-panel-content v-else>
                      <div class="pa-5">
                        <v-row>
                          <v-col cols="6">
                            <h2>Shipper Information</h2>
                            <v-divider></v-divider>
                            <div class="pa-5">
                              <p>
                                <strong>Nama</strong>:
                                {{ order.detail.Order.ShipperLoc.Nama }}
                              </p>
                              <p>
                                <strong>Telp</strong> :
                                {{ order.detail.Order.ShipperLoc.Telp }}
                              </p>
                              <p>
                                <strong>Alamat</strong>:
                                {{ order.detail.Order.ShipperLoc.Alamat }}
                              </p>
                            </div>
                          </v-col>
                          <v-col cols="6">
                            <h2>Recipient Information</h2>
                            <v-divider></v-divider>

                            <div class="pa-5">
                              <v-row>
                                <v-col cols="6">
                                  <p>
                                    <strong>Nama</strong>:
                                    {{ order.detail.Order.RecipientInter.Nama }}
                                  </p>
                                  <p>
                                    <strong>Telp</strong>:
                                    {{ order.detail.Order.RecipientInter.Telp }}
                                  </p>
                                  <p>
                                    <strong>Email</strong>:
                                    {{
                                      order.detail.Order.RecipientInter.Email
                                    }}
                                  </p>
                                  <p>
                                    <strong>Contact Person</strong>:
                                    {{
                                      order.detail.Order.RecipientInter
                                        .ContactPerson
                                    }}
                                  </p>
                                  <p>
                                    <strong>Nama Negara</strong>:
                                    {{
                                      order.detail.Order.RecipientInter.Country
                                        .CountryName
                                    }}
                                  </p>
                                </v-col>
                                <v-col cols="6">
                                  <p>
                                    <strong>Alamat</strong> :
                                    {{
                                      order.detail.Order.RecipientInter.Alamat
                                    }}
                                  </p>
                                  <p>
                                    <strong>Kodepos</strong> :
                                    {{
                                      order.detail.Order.RecipientInter.KodePos
                                    }}
                                  </p>
                                  <p>
                                    <strong>Service</strong> :
                                    {{ order.detail.Order.Moda }}
                                  </p>
                                  <p>
                                    <strong>Type ID</strong>:
                                    {{
                                      order.detail.Order.RecipientInter
                                        .IDType ?? "-"
                                    }}
                                  </p>
                                  <p>
                                    <strong>No. ID</strong>:
                                    {{
                                      order.detail.Order.RecipientInter.IDNo ??
                                      "-"
                                    }}
                                  </p>
                                </v-col>
                              </v-row>
                            </div>
                          </v-col>
                          <v-col cols="12">
                            <h3>Package Information</h3>
                            <v-divider></v-divider>
                            <v-row>
                              <v-col cols="6">
                                <div class="pa-5">
                                  <p>
                                    <strong>Package Type</strong> :
                                    {{ order.detail.Order.Kategory }}
                                  </p>
                                  <p>
                                    <strong>Volume</strong> :
                                    {{ order.detail.Order.Collies[0].Panjang }}
                                    x
                                    {{ order.detail.Order.Collies[0].Lebar }} x
                                    {{ order.detail.Order.Collies[0].Tinggi }}
                                  </p>
                                  <p>
                                    <strong>Total Item</strong> :{{
                                      order.detail.Order.ItemDetails.length
                                    }}
                                  </p>
                                  <p>
                                    <strong>Keterangan</strong> :{{
                                      order.detail.Keterangan
                                    }}
                                  </p>
                                  <p>
                                    <strong>Instruksi Khusus</strong> :{{
                                      order.detail.Instruction
                                    }}
                                  </p>
                                  <p><strong>Item Value</strong> : IDR</p>

                                  <v-card
                                    outlined
                                    class="pa-3"
                                    v-if="
                                      order.detail.Order.AddCosts.length > 0
                                    "
                                  >
                                    <h4>Additional Price</h4>
                                    <hr />
                                    <v-simple-table>
                                      <thead>
                                        <tr>
                                          <td>Name Cost</td>
                                          <td>Price</td>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr
                                          v-for="item in order.detail.Order
                                            .AddCosts"
                                          :key="item.IDAddCost"
                                        >
                                          <template v-if="item.Price > 0">
                                            <td>{{ item.NameCost }}</td>
                                            <td>{{ item.Price }}</td>
                                          </template>
                                        </tr>
                                      </tbody>
                                    </v-simple-table>
                                  </v-card>
                                  <br />
                                  <v-card
                                    outlined
                                    class="pa-3"
                                    v-if="
                                      order.detail.Order.Surcharges.length > 0
                                    "
                                  >
                                    <h4>Surcharge</h4>
                                    <hr />
                                    <v-simple-table>
                                      <thead>
                                        <tr>
                                          <td>Name Cost</td>
                                          <td>Price</td>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr
                                          v-for="item in order.detail.Order
                                            .Surcharges"
                                          :key="item.IDSurcharge"
                                        >
                                          <template v-if="item.Price > 0">
                                            <td>{{ item.NameSurcharge }}</td>
                                            <td>{{ item.Price }}</td>
                                          </template>
                                        </tr>
                                      </tbody>
                                    </v-simple-table>
                                  </v-card>
                                </div>
                              </v-col>
                              <v-col cols="6">
                                <div class="pa-5">
                                  <div class="ma-2">
                                    <v-simple-table
                                      v-if="
                                        order.detail.Order.ItemDetails.length >
                                        0
                                      "
                                    >
                                      <thead>
                                        <tr>
                                          <th>Item Description</th>
                                          <th>Item Weight</th>
                                          <th>Item Price</th>
                                          <th>Item HSCode</th>
                                          <th>Item Pieces</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr
                                          v-for="item in order.detail.Order
                                            .ItemDetails"
                                          :key="item.IDItem"
                                        >
                                          <td>{{ item.ItemDescription }}</td>
                                          <td>{{ item.ItemWeight }}</td>
                                          <td>{{ item.ItemPrice }}</td>
                                          <td>{{ item.ItemHSCode }}</td>
                                          <td>{{ item.ItemPieces }}</td>
                                        </tr>
                                      </tbody>
                                    </v-simple-table>
                                  </div>
                                  <br />
                                  <p>
                                    <strong>Total Berat (Kg)</strong> :{{
                                      order.detail.TotalBerat
                                    }}
                                  </p>

                                  <p>
                                    <strong>Nilai Barang</strong> :{{
                                      order.detail.EstimasiNilai
                                    }}
                                  </p>

                                  <p>
                                    <strong>Ongkir 3PL</strong> :{{
                                      order.detail.Price3PL
                                    }}
                                  </p>

                                  <p>
                                    <strong>Asuransi</strong> :{{
                                      order.detail.Order.Insurance
                                    }}
                                  </p>
                                </div>
                              </v-col>

                              <v-col cols="12">
                                <h2>Detail Biaya</h2>
                                <v-divider></v-divider>
                                <v-simple-table>
                                  <thead>
                                    <tr>
                                      <th>Koli</th>
                                      <th>Total Berat</th>
                                      <th>Total Biaya Packing</th>
                                      <th>Biaya Kirim</th>
                                      <th>Diskon</th>
                                      <th>PPN</th>
                                      <th>Asuransi</th>
                                      <th>Total Biaya</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>{{ order.detail.TotalKoli }}</td>
                                      <td>{{ order.detail.TotalBerat }} Kg</td>
                                      <td>
                                        {{
                                          toRupiah(
                                            order.detail.Order.TotalBiayaPacking
                                          )
                                        }}
                                      </td>
                                      <td>
                                        {{
                                          toRupiah(
                                            order.detail.TotalBiaya -
                                              (order.detail.Diskon +
                                                order.detail.PPN +
                                                order.detail.Order
                                                  .TotalBiayaPacking +
                                                order.detail.Order.Insurance)
                                          )
                                        }}
                                      </td>
                                      <td>
                                        {{ toRupiah(order.detail.Diskon) }}
                                      </td>
                                      <td>{{ toRupiah(order.detail.PPN) }}</td>
                                      <td>
                                        {{
                                          toRupiah(order.detail.Order.Insurance)
                                        }}
                                      </td>
                                      <td>
                                        {{ toRupiah(order.detail.TotalBiaya) }}
                                      </td>
                                    </tr>
                                  </tbody>
                                </v-simple-table>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
                <div v-else class="text-center">Data Tidak Di temukan</div>
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment-timezone";
import { baseUrl } from "../../../constant/url";
import { getTokenCookie } from "../../../utils/cookies";
import timezone from "moment-timezone";
import { formatToRupiah } from "../../../utils/formatter";

export default {
  name: "DialogOrderTransactionComponent",
  //   props: ["idTrx"],
  data() {
    return {
      detailOrderDialog: false,
      snackbar: false,
      snackbarText: "",
      isLoading: false,
      dialog: false,
      photo: null,
      signature: null,
      dialogTracking: false,
      trackingDetail: [],
      loadingTrack: false,
      orderType: "",
      awbTrack: "",
      dialogPOD: false,
      linkPOD: "",
      showDialog: false,
      colorSnackbar: "cyan",
      items: [],
      headers: [
        {
          text: "Desc. Item",
          value: "DescriptionItem",
          sortable: false,
          filterable: false,
        },
        { text: "Koli", value: "JumlahKoli" },
        { text: "Panjang", value: "Panjang" },
        { text: "Lebar", value: "Lebar", filterable: false },
        { text: "Tinggi", value: "Tinggi", filterable: false },
        { text: "Berat Item (kg)", value: "Berat", filterable: false },
        { text: "Berat Volume (kg)", value: "BeratVolume", filterable: false },
        { text: "Terberat (kg)", value: "Terberat", filterable: false },
        { text: "Total Berat (kg)", value: "total", filterable: false },
        { text: "Packing Kayu", value: "IsPacking", filterable: false },
      ],
    };
  },

  methods: {
    toRupiah(value) {
      return formatToRupiah(value);
    },
    toggleDialog() {
      this.detailOrderDialog = !this.detailOrderDialog;
    },
    printLabel() {
      // this.$router.push(`/document/label?wb=${this.order.wbNumber}`);
      let routeData = this.$router.resolve(
        `/document/label?wb=${this.order.wbNumber}`
      );
      window.open(routeData.href, "_blank");
    },
    printStt() {
      let routeData = this.$router.resolve(
        `/document/receipt?wb=${this.order.wbNumber}`
      );
      window.open(routeData.href, "_blank");
    },
    printLabelInter() {
      // this.$router.push(`/document/label?wb=${this.order.wbNumber}`);
      let routeData = this.$router.resolve(
        `/document/label-inter?wb=${this.order.wbNumber}`
      );
      window.open(routeData.href, "_blank");
    },
    printSttInter() {
      let routeData = this.$router.resolve(
        `/document/receipt-inter?wb=${this.order.wbNumber}`
      );
      window.open(routeData.href, "_blank");
    },

    fetchOrder(idTrx) {
      this.isLoading = true;
      console.log(idTrx);
      axios({
        method: "get",
        url: `${baseUrl}/HubInboundOrders/transaction/${idTrx}`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
          status: "8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23",
        },
      })
        .then((res) => {
          console.log(res);
          this.items = res.data.data.map((order) => {
            try {
              let dest, namaShipper;
              if (order.Order.Recipient) {
                dest = order.Order.Recipient.City.NamaCity;
                namaShipper = order.Order.Recipient.Nama;
              } else {
                dest = order.Order.RecipientInter.Country.CountryName;
                namaShipper = order.Order.RecipientInter.Nama;
              }

              return {
                detail: order,
                idOrder: order.OrderIDOrder,
                wbNumber: order.WBNumber,
                date: moment(order.DateCreated)
                  // .add(7, "hours")
                  .format("YYYY-MM-DD, HH:mm"),
                origin: order.Order.ShipperLoc.NamaCity,
                shipper: order.Order.ShipperLoc.Nama,
                phone: order.Order.ShipperLoc.Telp,
                recipient: namaShipper,
                destination: dest,
                orderType: order.Order.OrderType,
                colly: order.TotalKoli,
                weight: order.TotalBerat,
                status: order.PackageStatus.Status,
                idStatus: order.PackageStatus.IDPackageStatus,
                price: formatToRupiah(order.TotalBiaya),
                insurance: order.Order.Insurance,
                isInsurance: order.Order.IsInsurance,
                detailColly: order.Order.Collies,
                price3PL: order.Price3PL,
                resi3PL: order.Resi3PL,
                vendor: order.Order.Vendor,
                kategory: order.Order.Kategory,
                moda: order.Order.Moda,
                dfod: order.Order.Dfod,
                linkPOD: order.Order.LinkPOD,
                idTransaction: order.Order.IDTransaction,
              };
            } catch (error) {
              console.log(error);
            }
          });
          this.isLoading = false;
        })
        .catch(() => {
          this.items = [];
          this.isLoading = false;
        });
    },
    formatDate(date) {
      return timezone(date)
        .local("id")
        .tz("Asia/Jakarta")
        .format("DD-MM-YYYY HH:MM:SS");
    },
  },
};
</script>

<style lang="scss" scoped></style>
