<template>
  <div class="Wallet">
    <v-row>
      <v-col cols="12" sm="12">
        <v-card class="pa-3" outlined tile>
          <div>
            <v-card class="pa-5" outlined tile>
              <v-row>
                <v-col cols="2" class="text-center">
                  <v-icon large color="orange  darken-2">mdi-wallet</v-icon>
                  <div class="pb-2">
                    Saldo Aktif : <br />
                    <v-progress-circular
                      indeterminate
                      color="primary"
                      v-if="isLoading"
                    ></v-progress-circular>
                    <strong v-else> {{ balance }}</strong>
                  </div>
                </v-col>
                <v-col cols="8">
                  <div class="d-flex flex-row align-start">
                    <v-menu
                      ref="menu"
                      v-model="menuDate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="startDate"
                          label="From"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="startDate"
                        no-title
                        scrollable
                        @input="menuDate = false"
                      >
                      </v-date-picker>
                    </v-menu>

                    <v-menu
                      ref="menu"
                      v-model="menuDateend"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="endDate"
                          label="To"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="endDate"
                        no-title
                        scrollable
                        @input="menuDateend = false"
                      >
                      </v-date-picker>
                    </v-menu>

                    <v-btn
                      depressed
                      color="cyan"
                      class="mt-4 ml-3 white--text"
                      @click="submit"
                      small
                    >
                      Search
                    </v-btn>
                    <v-btn
                      depressed
                      color="red"
                      class="mt-4 ml-3 white--text"
                      @click="reset"
                      small
                    >
                      Reset
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </div>
          <div class="mt-3">
            <v-card-text>
              <v-row>
                <v-col>
                  <v-card>
                    <v-card-title>
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-card-title>
                    <v-data-table
                      :headers="headers"
                      :items="transaction"
                      :search="search"
                      :loading="isLoading"
                      :hide-default-footer="true"
                      disable-pagination
                      disable-sort
                    >
                      <template v-slot:item.Date="{ item }">
                        <span>{{ formatDate(item.Date) }}</span>
                      </template>
                      <template v-slot:item.channel_category="{ item }">
                        <span>{{ item.channel_category }}</span> -
                        <strong
                          ><span>{{ item.channel_code }}</span></strong
                        >
                      </template>
                      <template v-slot:item.amount="{ item }">
                        <span
                          ><strong>{{
                            formatToRupiah(item.amount)
                          }}</strong></span
                        >
                      </template>
                      <template v-slot:item.fee="{ item }">
                        <span>{{ formatToRupiah(item.fee.xendit_fee) }}</span>
                      </template>
                      <template v-slot:item.status="{ item }">
                        <v-chip color="green" text-color="white">{{
                          item.status
                        }}</v-chip>
                      </template>
                    </v-data-table>
                  </v-card>
                  <v-col>
                    <v-btn block @click="loadMore" :loading="isLoading"
                      >Load More</v-btn
                    >
                  </v-col>
                </v-col>
              </v-row>
            </v-card-text>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar timeout="2000" v-model="snackbar" top color="orange">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { formatToRupiah, externalFormatID } from "@/utils/formatter.js";
import axios from "axios";
import { urlVA } from "../../../constant/url";
import { getTokenCookie, getProfileCookie } from "../../../utils/cookies";
import timezone from "moment-timezone";
import exportFromJSON from "export-from-json";

export default {
  name: "Wallet",
  data: () => ({
    isLoading: false,
    snackbar: false,
    snackbarText: "",
    popupTopup: false,
    search: "",
    searchIn: "",
    searchOut: "",
    balance: 0,
    headers: [
      {
        text: "Date",
        align: "start",
        filterable: false,
        value: "created",
      },
      { text: "Type", value: "type" },
      { text: "Status", value: "status" },
      { text: "Jenis Trx", value: "channel_category" },
      { text: "No.ref", value: "reference_id" },
      { text: "Amount", value: "amount" },
      { text: "Cashflow", value: "cashflow" },
      { text: "Fee Xendit", value: "fee" },
    ],
    listBank: [],
    listVA: [],
    transaction: [],
    dataVA: {
      bankCode: "",
      amount: 500000,
      name: getProfileCookie().NamaDepan,
      description: "TOPUP HO",
    },
    amount: 500000,
    IDHO: getProfileCookie().KCX_HOIDHO,
    numberVA: "",
    externalID: externalFormatID(getProfileCookie()),
    menuDate: false,
    menuDateend: false,
    startDate: timezone()
      .tz("Asia/Jakarta")
      .startOf("month")
      .format("YYYY-MM-DD"),
    endDate: timezone().tz("Asia/Jakarta").endOf("month").format("YYYY-MM-DD"),
    afterId: "",
  }),
  created() {
    this.fetchTransaction(this.startDate, this.endDate);
    this.fetchBalance();
  },
  computed: {},
  watch: {},
  methods: {
    submit() {
      const startDt = timezone(this.startDate).tz("Asia/Jakarta");
      const endDt = timezone(this.endDate).tz("Asia/Jakarta");
      if (startDt > endDt) {
        this.snackbar = true;
        this.colorSnacbar = "red";
        this.snackbarText = "your end date must before the start date";
        return;
      }

      this.fetchTransaction(
        timezone(this.startDate).tz("Asia/Jakarta").format("YYYY-MM-DD"),
        timezone(this.endDate).tz("Asia/Jakarta").format("YYYY-MM-DD")
      );
    },
    reset() {
      (this.startDate = timezone()
        .tz("Asia/Jakarta")
        .startOf("month")
        .format("YYYY-MM-DD")),
        (this.endDate = timezone()
          .tz("Asia/Jakarta")
          .endOf("month")
          .format("YYYY-MM-DD")),
        this.fetchTransaction(this.startDate, this.endDate);
    },
    async fetchBalance() {
      this.isLoading = true;
      const res = await axios({
        method: "get",
        url: `${urlVA}/balance`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      });
      // console.log(res.data.result.data.balance);
      this.balance = formatToRupiah(res.data.result.data.balance ?? 0);
      this.isLoading = false;
    },
    async fetchTransaction(startDate, endDate) {
      this.transaction = [];
      this.isLoading = true;
      let query = ``;

      if (startDate) query += `?startDate=${startDate}`;
      if (startDate && endDate) query += `&endDate=${endDate}`;
      const res = await axios({
        method: "get",
        url: `${urlVA}/transaction${query}`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      });
      // this.transaction = res.data.result.data.data;
      this.transaction.push(...res.data.result.data.data);

      if (res.data.result.data.has_more) {
        let splitId = res.data.result.data.links[0].href.split("=");
        this.afterId = splitId[splitId.length - 1];
      }
      // console.log(this.transaction);
      this.isLoading = false;
    },
    async loadMore() {
      if (this.afterId) {
        this.isLoading = true;
        const res = await axios({
          method: "get",
          url: `${urlVA}/transaction?afterId=${this.afterId}`,
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
          },
        });
        this.transaction.push(...res.data.result.data.data);
        if (res.data.result.data.has_more) {
          let splitId = res.data.result.data.links[0].href.split("=");
          this.afterId = splitId[splitId.length - 1];
        } else {
          this.afterId = "";
        }
        this.isLoading = false;
      } else {
        this.snackbar = true;
        this.colorSnacbar = "red";
        this.snackbarText = "Sudah Tidak Ada Lagi Transaksi";
      }
    },
    formatToRupiah(amount) {
      return formatToRupiah(amount);
    },
    formatDate(date) {
      return timezone(date).tz("Asia/Jakarta").format("DD-MM-YYYY HH:mm:ss");
    },
    exportToExcel() {
      const currentData = this.transaction;
      const data = [];
      let number = 1;

      currentData.forEach((el) => {
        data.push({
          Number: number,
          "Trx. ID": el.TransactionID,
          Date: el.Date,
          "Trx. Type": el.TransactionType,
          "Order ID": el.OrderID,
          Debit: el.IsDebit ? "" : el.Amount,
          Credit: el.IsDebit ? el.Amount : "",
          Balance: el.NewBalance,
        });

        number++;
      });

      const fileName = "RecapTransaction";
      const exportType = "xls";

      exportFromJSON({
        data,
        fileName,
        exportType,
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
