<template>
  <div class="DialogApproveTopupHub">
    <v-dialog v-model="dialogApproveTF" persistent width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn depressed color="primary" v-bind="attrs" v-on="on" x-small>
          <v-icon dark> mdi-credit-card </v-icon> Bukti TF
        </v-btn>
      </template>

      <v-card>
        <v-card-title>Bukti Transfer Topup HO</v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-subtitle>Nama HO</v-list-item-subtitle>
                <v-list-item-title>{{ itemData.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-subtitle>Jumlah Topup</v-list-item-subtitle>
                <v-list-item-title>{{
                  formatToRupiah(itemData.expected_amount)
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-subtitle>Nama Bank</v-list-item-subtitle>
                <v-list-item-title>{{ itemData.bank_code }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-subtitle>Nomor Rekening</v-list-item-subtitle>
                <v-list-item-title>{{
                  itemData.account_number
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-subtitle>Tanggal TF</v-list-item-subtitle>
                <v-list-item-title>{{
                  formatDate(itemData.createdAt)
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <a :href="itemData.BuktiTF" target="_blank"
              ><v-img height="100%" :src="itemData.BuktiTF"
            /></a>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="cyan" outlined @click="dialogApproveTF = false">
            close
          </v-btn>

          <div v-if="itemData.status == 'REVIEW' && itemData.BuktiTF != null">
            <v-btn
              color="red"
              class="white--text ml-2"
              depressed
              @click="modalReject = true"
            >
              Reject
            </v-btn>
            <v-btn
              color="cyan"
              class="white--text ml-2"
              depressed
              @click="modalApprove = true"
            >
              Approve
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="modalApprove" persistent max-width="600">
      <v-card>
        <v-card-title class="text-h5">Approve</v-card-title>
        <v-card-text
          >Apakah anda Yakin Approve Topup?
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-subtitle>Nama Ho</v-list-item-subtitle>
              <v-list-item-title>{{ itemData.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-subtitle>Jumlah Topup</v-list-item-subtitle>
              <v-list-item-title>{{
                formatToRupiah(itemData.expected_amount)
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="modalApprove = false"
            >Close</v-btn
          >
          <v-btn color="green darken-1" text @click="approve">Approve</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="modalReject" persistent max-width="600">
      <v-card>
        <v-card-title class="text-h5 red--text">Reject</v-card-title>
        <v-card-text
          >Apakah anda Yakin Mereject Topup?
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-subtitle>Nama Ho</v-list-item-subtitle>
              <v-list-item-title>{{ itemData.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-subtitle>Jumlah Topup</v-list-item-subtitle>
              <v-list-item-title>{{
                formatToRupiah(itemData.expected_amount)
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="modalReject = false"
            >Close</v-btn
          >
          <v-btn color="green darken-1" text @click="reject">Reject</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" top :color="colorSnackbar">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import { baseUrl } from "../../../constant/url";
import { getTokenCookie } from "../../../utils/cookies";
import { formatToRupiah } from "@/utils/formatter.js";
import timezone from "moment-timezone";

export default {
  name: "DialogApproveTopupHO",
  props: {
    loadRiwayatTopup: { type: Function },
    // loadBalance: { type: Function },
    itemData: { type: Object },
    startDate: { type: String },
    endDate: { type: String },
    // balance: { type: Number },
  },
  data() {
    return {
      loadingButton: false,
      dialogApproveTF: false,
      valid: false,
      requiredRule: [(value) => !!value || "Required"],
      snackbar: false,
      snackbarText: "",
      colorSnackbar: "red",
      modalApprove: false,
      modalReject: false,
    };
  },

  methods: {
    formatToRupiah(amount) {
      return formatToRupiah(amount);
    },
    formatDate(date) {
      return timezone(date).tz("Asia/Jakarta").format("L");
    },
    async reject() {
      try {
        this.loadingButton = true;
        axios({
          url: `${baseUrl}/deposit/rejectTFHO`,
          method: "post",
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
          },
          data: {
            ID: this.itemData.id,
          },
        })
          .then((res) => {
            this.snackbar = true;
            this.colorSnackbar = "green";
            this.snackbarText = res.data.message.info[0];
            this.dialogUploadTF = false;
            this.loadRiwayatTopup(this.startDate, this.endDate);
            // this.loadBalance();
          })
          .catch((error) => {
            this.snackbar = true;
            this.colorSnackbar = "red";
            this.snackbarText = error;
          });
      } catch (error) {
        this.snackbar = true;
        this.colorSnackbar = "red";
        this.snackbarText = error;
      } finally {
        this.modalReject = false;
        this.dialogApproveTF = false;
      }
    },
    async approve() {
      try {
        // if (this.balance < this.itemData.Amount) {
        //   this.snackbar = true;
        //   this.colorSnackbar = "red";
        //   this.snackbarText = "Saldo Anda Tidak Cukup. Silahkan TopUp!";
        //   setTimeout(() => {
        //     this.$router.push("/dashboard/wallet");
        //   }, 1000);
        //   return;
        // }
        this.loadingButton = true;
        axios({
          url: `${baseUrl}/deposit/approveTFHO`,
          method: "post",
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
          },
          data: {
            ID: this.itemData.id,
          },
        })
          .then((res) => {
            this.snackbar = true;
            this.colorSnackbar = "green";
            this.snackbarText = res.data.message.info[0];
            this.dialogUploadTF = false;
            this.loadRiwayatTopup(this.startDate, this.endDate);
            // this.loadBalance();
          })
          .catch((error) => {
            this.snackbar = true;
            this.colorSnackbar = "red";
            this.snackbarText = error;
          });
      } catch (error) {
        this.snackbar = true;
        this.colorSnackbar = "red";
        this.snackbarText = error;
      } finally {
        this.modalApprove = false;
        this.dialogApproveTF = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
