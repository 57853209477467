<template>
  <div class="HeaderComponent">
    <div class="Container">
      <div class="d-flex align-center">
        <v-btn
          @click="$emit('toggleSidebar')"
          class="ma-2"
          text
          icon
          color="white"
        >
          <v-icon>mdi-menu</v-icon>
        </v-btn>
        <h1 class="ml-2">{{ $route.name }}</h1>
      </div>

      <div class="HeaderComponent-Profile">
        <user-menu-component />
      </div>
    </div>
  </div>
</template>

<script>
import UserMenuComponent from "./UserMenuComponent.vue";

export default {
  name: "HeaderComponent",
  components: { UserMenuComponent },
};
</script>

<style lang="scss" scoped>
.HeaderComponent {
  background-color: #2a3996;
  height: 60px;
  position: sticky;
  top: 0;
  box-shadow: 1px 2px 10px rgba(128, 128, 128, 0.281);
  z-index: 1;
  color: white !important;

  .Container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 16px;
  }

  &-Profile {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .v-btn__content {
    color: white !important;
  }
}
</style>
