<template>
  <div class="KomisiStore">
    <div class="KomisiStore-Option">
      <v-container>
        <v-row>
          <v-col sm="4" class="pa-0">
            <v-text-field
              outlined
              dense
              v-model="ho"
              label="Search HO"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>

      <dialog-add-komisi-component @refresh="fetchHo" :listVia="listVia" />
    </div>

    <v-data-table
      :headers="headers"
      :items="items"
      :search="ho"
      :sort-by="sortBy"
      item-key="idHo"
      :loading="isLoading"
      :expanded.sync="expanded"
      :single-expand="true"
      show-expand
      @item-expanded="loadDetails"
      disable-sort
    >
      <template v-slot:top>
        <dialog-detail-hub-component :ho="itemDetail" ref="viewHoDialog" />
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-btn depressed class="mr-2" small color="green" @click="view(item)">
          <v-icon color="white"> mdi-eye </v-icon>
        </v-btn>
      </template>
      <template v-slot:expanded-item="{ item, headers }">
        <td :colspan="headers.length" class="pa-0">
          <drop-down-detail-komisi
            :komisi="item"
            :detailKomisi="komisiDetail"
          />
        </td>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import DialogAddKomisiComponent from "../../../components/dashboard/module/DialogAddKomisiComponent.vue";
import DialogDetailHubComponent from "../../../components/dashboard/module/DialogDetailHubComponent.vue";
import DropDownDetailKomisi from "../../../components/dashboard/module/DropDownDetailKomisi.vue";

import axios from "axios";
import { baseUrl } from "../../../constant/url";
import { getTokenCookie, getProfileCookie } from "../../../utils/cookies";

export default {
  name: "KomisiStore",
  components: {
    DialogAddKomisiComponent,
    DialogDetailHubComponent,
    DropDownDetailKomisi,
  },
  data() {
    return {
      ho: "",
      isLoading: false,
      itemDetail: {},
      options: [
        { text: "All", value: "" },
        { text: "Jakarta", value: "Jakarta" },
        { text: "Bandung", value: "Bandung" },
        { text: "Surabaya", value: "Surabaya" },
        { text: "Medan", value: "Medan" },
      ],
      sortBy: ["idHo", "hoName", "city", "status"],
      headers: [
        { text: "Name", value: "hoName" },
        { text: "Kota", value: "city", filterable: false },
        { text: "Contact", value: "phone", filterable: false },
        { text: "Email", value: "email", filterable: false },

        { text: "Status", value: "status", filterable: false },
        { text: "Action", value: "action", sortable: false, filterable: false },
      ],
      items: [],
      listVia: [],
      expanded: [],
      dKomisi: [],
      detail: [],
      idHO: getProfileCookie().KCX_HOIDHO,
    };
  },
  methods: {
    view(item) {
      this.itemDetail = item;
      this.$refs.viewHoDialog.toggleDialog();
      this.$refs.viewHoDialog.fetchHub(item.idHo);
    },
    fetchHo() {
      this.isLoading = true;
      axios({
        method: "get",
        url: `${baseUrl}/Ho`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
        .then((res) => {
          this.isLoading = false;
          const filteredItem = res.data.data.filter((el) => el.IDHO != 0);

          this.items = filteredItem.map((ho) => {
            return {
              idHo: ho.IDHO,
              hoName: ho.NamaHO,
              city: ho.City.NamaCity,
              idCity: ho.City.IDCity,
              phone: ho.Telp,
              email: ho.Email,
              supervisor: ho.Supervisor,
              description: ho.Keterangan,
              address: ho.Alamat,
              status: ho.Status,
            };
          });
        })
        .catch(() => {
          this.isLoading = false;
          this.items = [];
        });
    },
    fetchVia() {
      axios({
        method: "get",
        url: `${baseUrl}/Via`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
        .then((res) => {
          this.isLoading = false;
          this.listVia = res.data.data;
        })
        .catch(() => {
          this.isLoading = false;
          this.listVia = [];
        });
    },
    loadDetails({ item }) {
      this.isLoading = true;
      this.dKomisi = [];
      axios({
        method: "get",
        url: `${baseUrl}/Komisi/Detail/${item.idHo}/0`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
        .then((res) => {
          if (res.data.data) {
            this.dKomisi = res.data.data;
          } else {
            this.dKomisi = [];
          }
        })
        .catch((err) => {
          console.log(err);
          this.dKomisi = [];
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  computed: {
    komisiDetail() {
      let detail = [];
      for (let i = 0; i < this.dKomisi.length; i++) {
        detail.push({
          HOKomisi: this.dKomisi[i].HOKomisi,
          HubKomisi: this.dKomisi[i].HUBKomisi,
          Via: this.dKomisi[i].Vium.Via,
          KeteranganVia: this.dKomisi[i].Vium.Keterangan,
          TypeVia: this.dKomisi[i].Vium.TypeVia,
          IDVia: this.dKomisi[i].IDKCX_VIA,
          IDHo: this.dKomisi[i].IDKCX_HO,
          IDKomisi: this.dKomisi[i].IDKomisi,
          IDHub: this.dKomisi[i].IDKCX_HUB,
        });
      }

      return detail;
    },
  },
  created() {
    this.fetchHo();
    this.fetchVia();
  },
};
</script>

<style lang="scss" scoped>
.KomisiStore {
  background-color: white;
  border-radius: 5px;
  padding: 16px;

  &-Option {
    display: flex;
  }
}
</style>
