<template>
  <div class="DropDownDetailKomisi">
    <v-data-table :headers="headers" :items="detailKomisi" hide-default-footer>
      <template v-slot:[`item.action`]="{ item, index }">
        <dialog-edit-komisi-component
          :dataKomisi="item"
          :setDataKomisi="setDataKomisi"
          :index="index"
        />
      </template>
    </v-data-table>

    <v-snackbar timeout="2000" v-model="snackbar" top color="cyan">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import { getTokenCookie, getProfileCookie } from "../../../utils/cookies";
import { baseUrl } from "../../../constant/url";
import DialogEditKomisiComponent from "./DialogEditKomisiComponent.vue";

export default {
  name: "DropDownDetailOrder",
  components: {
    DialogEditKomisiComponent,
  },
  props: ["komisi", "detailKomisi"],
  data() {
    return {
      headers: [
        { text: "Via", value: "KeteranganVia" },
        { text: "Type", value: "TypeVia" },
        { text: "HO Komisi", value: "HOKomisi" },
        { text: "Action", value: "action" },
      ],
      dialog: false,
      photo: null,
      signature: null,
      snackbar: false,
      snackbarText: "",
      idHO: getProfileCookie().KCX_HOIDHO,
      dKomisi: [],
    };
  },

  methods: {
    setDataKomisi(index, komisi) {
      this.detailKomisi[index].HOKomisi = komisi;
    },
    getDataKomisi(idHub) {
      // console.log(idHub);
      this.dKomisi = [];
      this.detail = [];
      axios({
        method: "get",
        url: `${baseUrl}/Komisi/Detail/${this.idHO}/${idHub}`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
        .then((res) => {
          if (res.data.data) {
            this.dKomisi = res.data.data;
          } else {
            this.dKomisi = [];
            this.detail = [];
          }
        })
        .catch((err) => {
          console.log(err);
          this.dKomisi = [];
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
