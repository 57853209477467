<template>
  <div class="Wallet">
    <v-row>
      <v-col cols="12" sm="12">
        <v-card class="pa-3" outlined tile>
          <div class="mt-3">
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-card class="pa-3">
                    <v-card-title class="pa-5">
                      <v-row>
                        <!-- <v-col cols="3">
                          <div class="pb-2">
                            Saldo Aktif : <br />
                            <v-progress-circular
                              indeterminate
                              color="primary"
                              v-if="isLoading"
                            ></v-progress-circular>
                            <strong v-else>
                              {{ formatToRupiah(balance ?? 0) }}</strong
                            >
                          </div>
                        </v-col> -->
                        <v-col cols="3">
                          <v-select
                            label="HO"
                            item-text="text"
                            item-value="value"
                            :items="hoList"
                            v-model="ho"
                            placeholder="ALL HO"
                          ></v-select>
                        </v-col>
                        <v-col cols="6">
                          <div class="d-flex flex-row align-start">
                            <v-menu
                              ref="menu"
                              v-model="menuDate"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="startDate"
                                  label="From"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="startDate"
                                no-title
                                scrollable
                                @input="menuDate = false"
                              >
                              </v-date-picker>
                            </v-menu>

                            <v-menu
                              ref="menu"
                              v-model="menuDateend"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              min-width="auto"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="endDate"
                                  label="To"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="endDate"
                                no-title
                                scrollable
                                @input="menuDateend = false"
                              >
                              </v-date-picker>
                            </v-menu>

                            <v-btn
                              depressed
                              color="cyan"
                              class="mt-4 white--text"
                              @click="submit"
                              small
                            >
                              Search
                            </v-btn>
                            <v-btn
                              depressed
                              color="red"
                              class="mt-4 ml-3 white--text"
                              @click="reset"
                              small
                            >
                              Reset
                            </v-btn>
                            <v-btn
                              depressed
                              color="green"
                              class="mt-4 ml-3 white--text"
                              @click="exportToExcel"
                              small
                            >
                              Export
                            </v-btn>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card-title>

                    <v-card-text>
                      <v-data-table
                        :headers="headersTopup"
                        :items="filteredItems"
                        disable-sort
                        :loading="isLoading"
                        ><template v-slot:[`item.expected_amount`]="{ item }">
                          {{ formatToRupiah(item.expected_amount) }}
                        </template>
                        <template v-slot:[`item.created_at`]="{ item }">
                          {{ formatToDate(item.created_at) }}
                        </template>
                        <template v-slot:[`item.uploadTF`]="{ item }">
                          <dialog-approve-topup-component
                            :loadRiwayatTopup="getTopupHO"
                            :itemData="item"
                            :startDate="startDate"
                            :endDate="endDate"
                          />
                        </template>
                        <template v-slot:[`item.status`]="{ item }">
                          <span v-if="item.BuktiTF">
                            <span v-if="item.status == 'PAID'">
                              <v-chip
                                class="ma-2 white--text"
                                color="green"
                                small
                              >
                                PAID</v-chip
                              ></span
                            >
                            <span v-if="item.status == 'REJECT'">
                              <v-chip
                                class="ma-2 white--text"
                                color="red"
                                small
                              >
                                REJECT</v-chip
                              ></span
                            >

                            <span v-if="item.status == 'REVIEW'">
                              <v-chip
                                class="ma-2 white--text"
                                color="info"
                                small
                              >
                                REVIEW</v-chip
                              >
                            </span>
                          </span>
                          <span v-else>
                            <v-chip
                              class="ma-2 white--text"
                              color="secondary"
                              small
                            >
                              belum upload
                            </v-chip>
                          </span>
                          <button></button>
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar timeout="2000" v-model="snackbar" top :color="colorSnacbar">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { formatToRupiah, externalFormatID } from "@/utils/formatter.js";
import axios from "axios";
import { baseUrl } from "../../../constant/url";
import { getTokenCookie, getProfileCookie } from "../../../utils/cookies";
import timezone from "moment-timezone";
import DialogApproveTopupComponent from "../../../components/dashboard/inbound/DialogApproveTopupComponent.vue";
import exportFromJSON from "export-from-json";

export default {
  name: "Wallet",
  components: { DialogApproveTopupComponent },
  data: () => ({
    isLoading: false,
    snackbar: false,
    snackbarText: "",
    popupTopup: false,
    search: "",
    searchIn: "",
    searchOut: "",
    balance: 0,
    colorSnacbar: "green",
    loadingSaldo: false,
    showSaldo: false,
    menuDate: false,
    menuDateend: false,
    valid: false,
    listBank: [],
    listVA: [],
    transaction: [],
    transactionIn: [],
    transactionOut: [],
    dataVA: {
      bankCode: "",
      amount: 500000,
      name: getProfileCookie().NamaDepan,
      description: "TOPUP HO",
    },
    amount: 500000,
    IDHO: getProfileCookie().KCX_HOIDHO,
    numberVA: "",
    externalID: externalFormatID(getProfileCookie()),
    startDate: timezone()
      .tz("Asia/Jakarta")
      .startOf("month")
      .format("YYYY-MM-DD"),
    endDate: timezone().tz("Asia/Jakarta").endOf("month").format("YYYY-MM-DD"),
    message: "",
    sortBy: [
      // "packingId",
      "date",
      "destination",
      "totalColly",
      "totalWeight",
      // "status",
    ],
    sortByTopup: [
      // "packingId",
      "date",
      "destination",
      "totalColly",
      "totalWeight",
      // "status",
    ],
    city: "",
    toDate: "",
    fromDate: "",
    headersTopup: [
      {
        text: "Tanggal",
        value: "created_at",
        filterable: false,
      },
      { text: "Nama HO", value: "name", filterable: false },
      { text: "Bank", value: "bank_code", filterable: false },
      { text: "Norek", value: "account_number", filterable: false },
      {
        text: "Nominal",
        value: "expected_amount",
        filterable: false,
      },
      { text: "Bukti TF", value: "uploadTF", filterable: false },
      { text: "Status", value: "status", filterable: false },
    ],
    items: [],
    itemsTopup: [],
    selected: [],
    isShowCheckbox: false,
    norekHO: [],
    HubID: getProfileCookie().KCX_HubIDHub,
    dataRiwayat: "topup",
    hoList: [],
    ho: "",
    filteredItems: [],
  }),
  created() {
    // this.fetchBalance();
    this.getTopupHO(this.startDate, this.endDate);
  },
  computed: {},
  watch: {},
  methods: {
    generateList(data) {
      this.hoList = [...new Set(data.map((item) => item.name))];
    },
    filterHub() {
      this.filteredItems = this.filteredItems.filter((el) => {
        return el.nama == this.hub;
      });
    },
    formatToRupiah(amount) {
      return formatToRupiah(amount);
    },
    formatDate(date) {
      return timezone(date).tz("Asia/Jakarta").format("L");
    },
    loadData(mode) {
      this.dataRiwayat = mode;
    },
    // async fetchBalance() {
    //   this.isLoading = true;
    //   const IDHO = await getProfileCookie().KCX_HOIDHO;
    //   const res = await axios({
    //     method: "get",
    //     url: `${baseUrl}/Balance/${IDHO}`,
    //     headers: {
    //       Authorization: `Bearer ${getTokenCookie()}`,
    //     },
    //   });
    //   this.balance = res.data.data.balance;
    //   this.isLoading = false;
    // },
    reset() {
      (this.startDate = timezone()
        .tz("Asia/Jakarta")
        .startOf("month")
        .format("YYYY-MM-DD")),
        (this.endDate = timezone()
          .tz("Asia/Jakarta")
          .endOf("month")
          .format("YYYY-MM-DD")),
        this.getTopupHO(this.startDate, this.endDate);
    },
    exportToExcel() {
      const currentData = this.filteredItems;
      const data = [];
      let number = 1;

      currentData.forEach((el) => {
        data.push({
          Number: number,
          Tanggal: el.created_at,
          "Nama HO": el.name,
          Bank: el.bank_code,
          Norek: el.account_number,
          Nominal: el.expected_amount,
          Status: el.status,
        });
        number++;
      });

      const fileName = "RecapTransaction";
      const exportType = "xls";

      exportFromJSON({
        data,
        fileName,
        exportType,
      });
    },
    formatToDate(date) {
      // return date;
      return timezone(date).tz("Asia/Jakarta").format("DD-MM-YYYY HH:mm");
    },
    view() {
      this.showSaldo = !this.showSaldo;
    },
    submit() {
      this.filteredItems = this.itemsTopup;

      const startDt = timezone(this.startDate).tz("Asia/Jakarta");
      const endDt = timezone(this.endDate).tz("Asia/Jakarta");
      if (startDt > endDt) {
        this.snackbar = true;
        this.colorSnacbar = "red";
        this.snackbarText = "your end date must before the start date";
        return;
      }
      this.getTopupHO(
        timezone(this.startDate).tz("Asia/Jakarta").format("YYYY-MM-DD"),
        timezone(this.endDate).tz("Asia/Jakarta").format("YYYY-MM-DD")
      );
      if (this.ho) {
        this.filterHub();
      }
    },
    refresh() {
      this.getTopupHO(this.startDate, this.endDate);
    },

    async getTopupHO(startDate, endDate) {
      let query = ``;

      if (startDate) query += `?startDate=${startDate}`;
      if (startDate && endDate) query += `&endDate=${endDate}`;
      let riwayatTopup = await axios({
        method: "get",
        url: `${baseUrl}/Balance/Topup/HO${query}`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      });
      this.filteredItems = riwayatTopup.data.data;
      this.generateList(this.filteredItems);
    },
  },
};
</script>

<style lang="scss" scoped></style>
