import Cookies from "js-cookie";

export const cookiesName = {
  userProfile: "UserProfileHq",
  tokenKey: "TokenKeyHq",
};

export function getTokenCookie() {
  return Cookies.get(cookiesName.tokenKey);
}
export function setTokenCookie(value) {
  return Cookies.set(cookiesName.tokenKey, value, { expires: 7 });
}
export function removeTokenCookie() {
  return Cookies.remove(cookiesName.tokenKey);
}
export function getProfileCookie() {
  return JSON.parse(Cookies.get(cookiesName.userProfile));
}
export function setProfileCookie(value) {
  return Cookies.set(cookiesName.userProfile, JSON.stringify(value), {
    expires: 7,
  });
}
export function removeProfileCookie() {
  return Cookies.remove(cookiesName.userProfile);
}
