<template>
  <div class="Home">
    <v-row>
      <v-col cols="12" sm="12">
        <v-card class="pa-3" outlined tile>
          <div>
            <v-card class="pa-5" outlined tile>
              <v-row>
                <!-- <v-col cols="2" class="text-center">
                  <v-icon large color="orange  darken-2">mdi-wallet</v-icon>
                  <div class="pb-2">
                    Saldo Aktif : <br />
                    <v-progress-circular
                      indeterminate
                      color="primary"
                      v-if="isLoading"
                    ></v-progress-circular>
                    <strong v-else> {{ balance }}</strong>
                  </div>
                </v-col> -->
                <v-col cols="2">
                  <v-select
                    class="pt-3"
                    dense
                    label="HO"
                    item-text="NamaHO"
                    item-value="IDHO"
                    :items="hoList"
                    v-model="ho"
                  ></v-select>
                </v-col>
                <v-col cols="7">
                  <div class="d-flex flex-row align-start">
                    <v-menu
                      ref="menu"
                      v-model="menuDate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="startDate"
                          label="From"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="startDate"
                        no-title
                        scrollable
                        @input="menuDate = false"
                      >
                      </v-date-picker>
                    </v-menu>

                    <v-menu
                      ref="menu"
                      v-model="menuDateend"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="endDate"
                          label="To"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="endDate"
                        no-title
                        scrollable
                        @input="menuDateend = false"
                      >
                      </v-date-picker>
                    </v-menu>

                    <v-btn
                      depressed
                      color="cyan"
                      class="mt-4 ml-3 white--text"
                      @click="submit"
                      small
                    >
                      Search
                    </v-btn>
                    <v-btn
                      depressed
                      color="red"
                      class="mt-4 ml-3 white--text"
                      @click="reset"
                      small
                    >
                      Reset
                    </v-btn>
                    <v-btn
                      depressed
                      color="green"
                      class="mt-4 ml-3 white--text"
                      @click="exportToExcel"
                      small
                    >
                      Export
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </div>
          <div class="mt-3">
            <v-card flat>
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-card>
                      <v-card-title>
                        <v-text-field
                          v-model="search"
                          append-icon="mdi-magnify"
                          label="Search"
                          single-line
                          hide-details
                        ></v-text-field>
                      </v-card-title>
                      <v-data-table
                        :headers="headers"
                        :items="transaction"
                        :search="search"
                        :loading="isLoading"
                        disable-sort
                      >
                        <template v-slot:top>
                          <dialog-order-transaction-component
                            ref="detailOrderDialog"
                          />
                        </template>
                        <template v-slot:item.OrderID="{ item }">
                          <div class="pa-1">
                            <span
                              v-if="item.TransactionType == 'TOPUP' && item.va"
                            >
                              No. VA :
                              <strong>{{ item.va.account_number }}</strong>
                              <br />
                              Bank: {{ item.va.bank_code }}
                            </span>
                          </div>

                          <span v-if="item.topuphub">
                            <div class="pa-1">
                              Norek : <strong>{{ item.topuphub.Norek }}</strong>
                            </div>
                          </span>
                          <span v-else>
                            <div
                              class="pa-1"
                              v-for="(order, index) in item.order"
                              :key="index"
                            >
                              waybill :<a @click="showDetail(item.OrderID)"
                                ><strong>
                                  {{ order.InboundOrder.WBNumber }}</strong
                                ></a
                              >
                              <br />
                              berat : {{ order.InboundOrder.TotalBerat }}
                              <br />
                              biaya :
                              {{
                                formatToRupiah(order.InboundOrder.TotalBiaya)
                              }}
                              <br />
                              Debit :
                              {{
                                formatToRupiah(order.KomisiHub + order.KomisiHO)
                              }}
                              ({{ formatToRupiah(order.KomisiHub) }} +
                              {{ formatToRupiah(order.KomisiHO) }})
                              <br />
                              Collect amount :
                              {{
                                formatToRupiah(
                                  order.InboundOrder.TotalBiaya -
                                    (order.KomisiHub + order.KomisiHO)
                                )
                              }}
                            </div>
                          </span>
                        </template>
                        <template v-slot:item.TransactionType="{ item }">
                          <v-chip
                            v-if="item.TransactionType == 'ORDER'"
                            color="blue"
                            text-color="white"
                            x-small
                            >{{ item.TransactionType }}</v-chip
                          >
                          <v-chip
                            v-if="item.TransactionType == 'TRANSFER'"
                            color="blue"
                            text-color="white"
                            x-small
                            >{{ item.TransactionType }}</v-chip
                          >
                          <v-chip
                            v-if="item.TransactionType == 'TOPUP'"
                            color="green"
                            text-color="white"
                            x-small
                            >{{ item.TransactionType }}</v-chip
                          >
                          <v-chip
                            v-if="item.TransactionType == 'KOMISI'"
                            color="orange"
                            text-color="white"
                            x-small
                            >{{ item.TransactionType }}</v-chip
                          >
                          <v-chip
                            v-if="item.TransactionType == 'CANCEL ORDER'"
                            color="red"
                            text-color="white"
                            x-small
                            >{{ item.TransactionType }}</v-chip
                          >
                          <v-chip
                            v-if="item.TransactionType == 'REFUND'"
                            color="orange"
                            text-color="white"
                            x-small
                            >{{ item.TransactionType }}</v-chip
                          >
                        </template>
                        <template v-slot:item.Date="{ item }">
                          <span>{{ formatDate(item.Date) }}</span>
                        </template>
                        <template v-slot:item.HubName="{ item }">
                          <span v-if="item.TransactionType != 'TOPUP'">
                            Hub :
                            <span v-if="item.order[0]"
                              ><strong>{{ item.order[0].Hub.NamaHub }}</strong>
                              <br />
                              MasterHub:<strong>{{
                                item.Hub.Ho.NamaHO
                              }}</strong>
                            </span>
                            <span v-if="item.topuphub"
                              ><strong>{{ item.topuphub.Hub.NamaHub }}</strong>
                              <br />
                              MasterHub:<strong>{{
                                item.Hub.Ho.NamaHO
                              }}</strong>
                            </span>
                            <br />
                            <span v-if="item.order[0]">{{
                              item.order[0].OrderType
                            }}</span>
                            <span v-if="item.topuphub">{{
                              item.topuphub.NamaBank
                            }}</span></span
                          ><span v-else>
                            <span v-if="item.Ho.NamaHO"
                              >MasterHub :
                              <strong>{{ item.Ho.NamaHO }}</strong></span
                            >
                            <span v-if="item.Hub.NamaHub">
                              Hub :
                              <strong>{{ item.Hub.NamaHub }} </strong>
                              <br />
                              MasterHub:<strong>{{
                                item.Hub.Ho.NamaHO
                              }}</strong>
                            </span>
                            <br />

                            Topup Saldo
                          </span>
                        </template>
                        <template v-slot:item.Amount="{ item }">
                          <span
                            v-if="item.TransactionType == 'ORDER'"
                            class="blue--text"
                            ><strong>{{
                              formatToRupiah(item.Amount)
                            }}</strong></span
                          >
                          <span
                            v-if="item.TransactionType == 'TRANSFER'"
                            class="blue--text"
                            ><strong>{{
                              formatToRupiah(item.Amount)
                            }}</strong></span
                          >
                          <span
                            v-if="item.TransactionType == 'TOPUP'"
                            class="green--text"
                            ><strong>{{
                              formatToRupiah(item.Amount)
                            }}</strong></span
                          >
                          <span
                            v-if="item.TransactionType == 'KOMISI'"
                            class="orange--text"
                            ><strong>{{
                              formatToRupiah(item.Amount)
                            }}</strong></span
                          >
                          <span
                            v-if="item.TransactionType == 'CANCEL ORDER'"
                            class="red--text"
                            ><strong>{{
                              formatToRupiah(item.Amount)
                            }}</strong></span
                          >
                          <span
                            v-if="item.TransactionType == 'REFUND'"
                            class="orange--text"
                            ><strong>{{
                              formatToRupiah(item.Amount)
                            }}</strong></span
                          >
                        </template>
                      </v-data-table>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar timeout="2000" v-model="snackbar" top color="orange">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { formatToRupiah, externalFormatID } from "@/utils/formatter.js";
import axios from "axios";
import {
  baseUrl,
  // urlVA
} from "../../constant/url";
import { getTokenCookie, getProfileCookie } from "../../utils/cookies";
import timezone from "moment-timezone";
import exportFromJSON from "export-from-json";
import DialogOrderTransactionComponent from "../../components/dashboard/inbound/DialogOrderTransactionComponent.vue";

export default {
  name: "Dashboard",
  components: { DialogOrderTransactionComponent },
  data: () => ({
    isLoading: false,
    snackbar: false,
    snackbarText: "",
    popupTopup: false,
    menuDate: false,
    menuDateend: false,
    startDate: timezone()
      .tz("Asia/Jakarta")
      .startOf("month")
      .format("YYYY-MM-DD"),
    endDate: timezone().tz("Asia/Jakarta").endOf("month").format("YYYY-MM-DD"),
    search: "",
    searchIn: "",
    searchOut: "",
    balance: 0,
    headers: [
      { text: "Trx. ID", value: "TransactionID" },
      {
        text: "Date",
        align: "start",
        filterable: false,
        value: "Date",
      },
      { text: "Service", value: "HubName" },
      // { text: "Service", value: "Service" },
      { text: "Trx. Type", value: "TransactionType" },
      { text: "Detail Order", value: "OrderID" },
      { text: "Amount", value: "Amount" },
    ],
    listBank: [],
    listVA: [],
    transaction: [],
    transactionIn: [],
    transactionOut: [],
    IDHO: getProfileCookie().KCX_HOIDHO,
    numberVA: "",
    namaBank: "",
    externalID: externalFormatID(getProfileCookie()),
    expected_amount: 0,
    isLoadingTopup: false,
    hoList: [],
    ho: "",
  }),
  created() {
    this.fetchHo();
    // this.fetchBalance();
    this.fetchTransaction(this.startDate, this.endDate);
  },
  computed: {},
  watch: {},
  methods: {
    fetchHo() {
      this.isLoading = true;
      axios({
        method: "get",
        url: `${baseUrl}/Ho`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
        .then((res) => {
          this.isLoading = false;
          this.hoList = res.data.data;
        })
        .catch(() => {
          this.isLoading = false;
          this.items = [];
        });
    },
    submit() {
      const startDt = timezone(this.startDate).tz("Asia/Jakarta");
      const endDt = timezone(this.endDate).tz("Asia/Jakarta");
      if (startDt > endDt) {
        this.snackbar = true;
        this.colorSnacbar = "red";
        this.snackbarText = "your end date must before the start date";
        return;
      }

      this.fetchTransaction(
        timezone(this.startDate).tz("Asia/Jakarta").format("YYYY-MM-DD"),
        timezone(this.endDate).tz("Asia/Jakarta").format("YYYY-MM-DD")
      );
    },
    reset() {
      (this.startDate = timezone()
        .tz("Asia/Jakarta")
        .startOf("month")
        .format("YYYY-MM-DD")),
        (this.endDate = timezone()
          .tz("Asia/Jakarta")
          .endOf("month")
          .format("YYYY-MM-DD")),
        this.fetchTransaction(this.startDate, this.endDate);
      this.hub = "";
    },
    setAmount() {
      this.dataVA.amount = this.amount;
    },
    // async fetchBalance() {
    //   this.isLoading = true;
    //   const res = await axios({
    //     method: "get",
    //     url: `${urlVA}/balance`,
    //     headers: {
    //       Authorization: `Bearer ${getTokenCookie()}`,
    //     },
    //   });
    //   // console.log(res.data.result.data.balance);
    //   this.balance = formatToRupiah(res.data.result.data.balance ?? 0);
    //   this.isLoading = false;
    // },
    async fetchTransaction(startDate, endDate) {
      this.isLoading = true;
      let query = ``;

      if (startDate) query += `?startDate=${startDate}`;
      if (startDate && endDate) query += `&endDate=${endDate}`;
      const res = await axios({
        method: "get",
        url: `${baseUrl}/Balance/Transaction/Topup${query}`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
          hoid: this.ho,
        },
      });
      // console.log(res.data.data);
      this.transaction = res.data.data;
      this.transactionIn = res.data.data.filter((el) => el.IsDebit == true);
      this.transactionOut = res.data.data.filter((el) => el.IsDebit == false);

      this.isLoading = false;
    },

    formatToRupiah(amount) {
      return formatToRupiah(amount);
    },
    formatDate(date) {
      return timezone(date).tz("Asia/Jakarta").format("DD-MM-YYYY HH:mm");
    },
    showDetail(idTrx) {
      // console.log(idTrx);
      // this.idTrx = idTrx;
      this.$refs.detailOrderDialog.toggleDialog();
      this.$refs.detailOrderDialog.fetchOrder(idTrx);
    },
    exportToExcel() {
      const currentData = this.transaction;
      const data = [];
      let number = 1;

      currentData.forEach((el) => {
        data.push({
          Number: number,
          "Trx. ID": el.TransactionID,
          Date: el.Date,
          "Trx. Type": el.TransactionType,
          "Order ID": el.OrderID,
          Debit: el.IsDebit ? "" : el.Amount,
          Credit: el.IsDebit ? el.Amount : "",
          Balance: el.NewBalance,
        });

        number++;
      });

      const fileName = "RecapTransaction";
      const exportType = "xls";

      exportFromJSON({
        data,
        fileName,
        exportType,
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
