<template>
  <div class="UserManagement">
    <div class="UserManagement-Option">
      <v-container>
        <v-row>
          <v-col sm="4" class="pa-0">
            <v-select
              :items="options"
              v-model="groupUser"
              label="Group User"
              item-text="text"
              item-value="value"
              outlined
              dense
            ></v-select>
          </v-col>
        </v-row>
      </v-container>

      <dialog-add-user-component @refresh="fetchUser" />
    </div>

    <v-data-table
      :headers="headers"
      :items="items"
      :search="groupUser"
      :sort-by="sortBy"
      :loading="isLoading"
      loading-text="Loading... Please wait"
      disable-sort
    >
      <template v-slot:top>
        <dialog-view-user-component :user="itemDetail" ref="viewUserDialog" />

        <dialog-edit-user-component
          @refresh="fetchUser"
          :user="itemDetail"
          ref="editUserDialog"
        />

        <dialog-delete-user-component
          @refresh="fetchUser"
          :user="itemDetail"
          ref="deleteUserDialog"
        />

        <dialog-reset-password-component
          :user="itemDetail"
          ref="dialogResetPassword"
        />
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-btn class="mr-2" depressed small color="green" @click="view(item)">
          <v-icon color="white"> mdi-eye </v-icon>
        </v-btn>

        <v-btn class="mr-2" depressed small color="blue" @click="edit(item)">
          <v-icon color="white"> mdi-pencil </v-icon>
        </v-btn>
        <v-btn
          class="mr-2"
          small
          color="blue"
          depressed
          @click="resetPassword(item)"
        >
          <v-icon color="white"> mdi-lock </v-icon>
        </v-btn>

        <v-btn small color="error" depressed @click="deleteItem(item)">
          <v-icon color="white"> mdi-exclamation </v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import DialogAddUserComponent from "../../../components/dashboard/settings/DialogAddUserComponent.vue";
import DialogViewUserComponent from "../../../components/dashboard/settings/DialogViewUserComponent.vue";
import DialogEditUserComponent from "../../../components/dashboard/settings/DialogEditUserComponent.vue";
import DialogDeleteUserComponent from "../../../components/dashboard/settings/DialogDeleteUserComponent.vue";
import DialogResetPasswordComponent from "../../../components/dashboard/settings/DialogResetPasswordComponent.vue";
// import LoadingComponent from "../../../components/dashboard/LoadingComponent.vue";

import axios from "axios";
import { baseUrl } from "../../../constant/url";
import { getTokenCookie } from "../../../utils/cookies";

export default {
  name: "UserManagement",
  isLoading: false,
  components: {
    DialogAddUserComponent,
    DialogViewUserComponent,
    DialogEditUserComponent,
    DialogDeleteUserComponent,
    DialogResetPasswordComponent,
  },
  data() {
    return {
      isLoading: false,
      itemDetail: {},
      groupUser: null,
      options: [
        // { text: "All", value: "" },
        { text: "Super Admin HO", value: "SUPER_ADMIN_HO" },
        { text: "Manager HO", value: "MANAGER_HO" },
        { text: "Operation HO", value: "OPERATION_HO" },
        // { text: "Supervisor Hub", value: "SUPERVISOR_HUB" },
        // { text: "Data Entry Hub", value: "DATA_ENTRY_HUB" },
        // { text: "Operation Hub", value: "OPERATION_HUB" },
      ],
      sortBy: ["idUser", "name", "email", "type", "hubName", "status"],
      headers: [
        { text: "ID", value: "idUser", filterable: false },
        { text: "Name", value: "name", filterable: false },
        { text: "Email", value: "email", filterable: false },
        { text: "Group User", value: "type" },
        // { text: "Hub", value: "hubName" },
        { text: "Status", value: "status", filterable: false },
        { text: "Action", value: "action", sortable: false, filterable: false },
      ],
      items: [],
    };
  },
  methods: {
    view(item) {
      this.itemDetail = item;
      this.$refs.viewUserDialog.toggleDialog();
    },
    edit(item) {
      this.itemDetail = item;
      this.$refs.editUserDialog.toggleDialog();
    },
    deleteItem(item) {
      this.itemDetail = item;
      this.$refs.deleteUserDialog.toggleDialog();
    },
    resetPassword(item) {
      this.itemDetail = item;
      this.$refs.dialogResetPassword.toggleDialog();
    },
    fetchUser() {
      this.isLoading = true;

      axios({
        method: "get",
        url: `${baseUrl}/hq/UserHo`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
        .then((res) => {
          this.isLoading = false;
          const filteredItem = res.data.data.filter((el) => el.IDUser != 0);
          this.items = filteredItem.map((user) => {
            return {
              idUser: user.IDUser,
              name: `${user.NamaDepan} ${user.NamaBelakang}`,
              email: user.Email,
              type: user.UserType.Type,
              idUserType: user.UserType.IDUserType,
              hoName: user.Ho.NamaHO,
              idHo: user.Ho.IDHO,
              status: user.Status,
              noKtp: user.NoKTP,
              firstName: user.NamaDepan,
              lastName: user.NamaBelakang,
              phone: user.Telp,
              city: user.City.NamaCity,
              idCity: user.City.IDCity,
              idDistrict: user.Kecamatan.IDKecamatan,
              village: user.Kelurahan.NamaKelurahan,
              idVillage: user.Kelurahan.IDKelurahan,
              postCode: user.Kelurahan.KodePos,
              address: user.Alamat,
            };
          });
        })
        .catch(() => {
          this.isLoading = false;
          this.items = [];
        });
    },
  },
  created() {
    this.fetchUser();
  },
};
</script>

<style lang="scss" scoped>
.UserManagement {
  background-color: white;
  border-radius: 5px;
  padding: 16px;

  &-Option {
    display: flex;
  }
}
</style>
